import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link} from "react-router-dom";
import { Circles } from 'react-loader-spinner';

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength);
    //return text.substring(0, maxLength) + '...';
  }
  return text;
};

const stripHtmlTags = (html, maxLength) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const strippedText = tempDiv.textContent || tempDiv.innerText || "";
  return truncateText(strippedText, maxLength);
};

export default function Slider() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPlayIcon, setShowPlayIcon] = useState(true);
  const togglePlayIcon = () => {
    setShowPlayIcon(!showPlayIcon);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.shabdtoday.com/api/home/tajaKhabars"
        );
        setPosts(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Circles
          height="30"
          width="30"
          color="#f7265c"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }


  if (error) {
    return <div>Error: {error}</div>;
  }
  const baseUrl = "https://api.shabdtoday.com/";
  const image_resize = "https://api.shabdtoday.com/api/home/resize";

  return (
    <div>
      <div
        id="carouselExampleCaptions"
        className="carousel slide mb-1"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {posts.tazaKhabars.slice(0, 1).map((newsItem) => (
            <div key={newsItem.mainKhabarId}

              className={`carousel-item active ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>
                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                     src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                  className="d-block w-100  zoom"
                  loading="lazy"
                  sizes="(max-width: 600px) 100vw, 50vw"
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                 
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(1, 2).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>
                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
                 
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(2, 3).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>
                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
          
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(3, 4).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>
                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
                
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(4, 5).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>
                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
                 
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(5, 6).map((newsItem) => (
            <div  key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>
                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(6, 7).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>
                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img

                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
                 
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(7, 8).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>

                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
                 
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(8, 9).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>
                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
               
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link  to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(9, 10).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>

                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(10, 11).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>
                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom" 
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
                 
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link  to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider"  > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}{" "}
          {posts.tazaKhabars.slice(11, 12).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>
                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
                
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(12, 13).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>

                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
               
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(13, 14).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>

                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
                
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(14, 15).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>

                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
                 
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider"  > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(15, 16).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>

                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
                
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
          {posts.tazaKhabars.slice(16, 17).map((newsItem) => (
            <div key={newsItem.mainKhabarId}
              className={`carousel-item ${
                newsItem.newsImage && !newsItem.newsImage.endsWith(".mp4")
                  ? ""
                  : ""
              }`}
            >
              {newsItem.newsImage && newsItem.newsImage.endsWith(".mp4") ? (
                <div className="video-container" onClick={togglePlayIcon}>
                  <span
                    className={`ak-module-format-icon format-video ${
                      showPlayIcon ? "" : "hidden"
                    }`}
                  >
                    <i className="ak-icon fa fa-play" />
                  </span>

                  <video
                    controls
                    autoPlay
                    className="d-block w-100"
                   
                  >
                    <source
                      src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={image_resize + "?url=" + baseUrl + newsItem.newsImage + "&w=780&h=520&outtype=webp"}
                  width={780}
                  height={520}
                   className="d-block w-100  zoom"
                   loading="lazy"
                   sizes="(max-width: 600px) 100vw, 50vw"
                
                  alt={stripHtmlTags(newsItem.newsTag, 60)}
                />
              )}
              <div className="carousel-caption d-md-block slider">
              <Link to={`/SliderDetails/${newsItem.mainKhabarId}`} aria-label="Slider" > 
                <h1>{stripHtmlTags(newsItem.newsTag, 60)}</h1>
            
                </Link>
                <p> {stripHtmlTags(newsItem.newsHeading, 80)}</p>
              </div>
            </div>
          ))}
        </div>

        {/* End Anil comment */}

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>

        <div
          className="carousel tns-nav"
          style={{
            display: "flex",
            overflowX: "auto",
            minWidth: "34px",
            margin: "7px 5px",
          }}
        >
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={0}
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          >
            1
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={1}
            aria-label="Slide 2"
          >
            2
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={2}
            aria-label="Slide 3"
          >
            3
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={3}
            aria-label="Slide 4"
          >
            4
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={4}
            aria-label="Slide 5"
          >
            5
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={5}
            aria-label="Slide 6"
          >
            6
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={6}
            aria-label="Slide 7"
          >
            7
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={7}
            aria-label="Slide 8"
          >
            8
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={8}
            aria-label="Slide 9"
          >
            9
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={9}
            aria-label="Slide 10"
          >
            10
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={10}
            aria-label="Slide 11"
          >
            11
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={11}
            aria-label="Slide 12"
          >
            12
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={12}
            aria-label="Slide 13"
          >
            13
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={13}
            aria-label="Slide 14"
          >
            14
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={14}
            aria-label="Slide 15"
          >
            15
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={15}
            aria-label="Slide 16"
          >
            16
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={16}
            aria-label="Slide 17"
          >
            17
          </button>
        </div>

      </div>
    </div>
  );
}
