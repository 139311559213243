import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { saveAs } from "file-saver";

const EpaperDetails = () => {
  const { epaperId } = useParams();
  const [newsDetails, setNewsDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNewsDetails = async () => {
      try {
        const { data } = await axios.get(
          `https://api.shabdtoday.com/api/home/getEpaperDetails/${epaperId}`
        );
        setNewsDetails(data);
        setLoading(false);
      } catch (error) {
        setError("समाचार विवरण लाने में त्रुटि");
        setLoading(false);
      }
    };

    fetchNewsDetails();
  }, [epaperId]);

  const baseUrl = "https://api.shabdtoday.com/";

  const handleDownload = async () => {
    const filePath = newsDetails.pdfUrlNews;

    if (!filePath) {
      alert("फाइल का पथ उपलब्ध नहीं है।");
      return;
    }

    try {
      const response = await axios.get(`${baseUrl}${filePath}`, {
        responseType: "blob", // Expecting blob format for image
      });

      if (response.status === 200) {
        const fileName = filePath.split("/").pop(); // Extract file name from URL
        const blob = new Blob([response.data], { type: "image/jpeg" }); // Ensure the correct type
        saveAs(blob, fileName); // Save the blob as a file using file-saver
      } else {
        alert("फाइल डाउनलोड करने में त्रुटि। कृपया पुनः प्रयास करें।");
      }
    } catch (error) {
      console.error("फाइल डाउनलोड करने में त्रुटि:", error);
      alert("फाइल डाउनलोड करने में त्रुटि। कृपया पुनः प्रयास करें।");
    }
  };

  if (loading) return <div>लोड हो रहा है...</div>;
  if (error) return <div>{error}</div>;
  if (!newsDetails) return <div>इस ई-पेपर के लिए कोई विवरण उपलब्ध नहीं है।</div>;

  return (
    <div style={{ backgroundColor: "black" }}>
      <HelmetProvider>
        <Helmet>
          <title>शब्द टुडे | ई-पेपर</title>
        </Helmet>
      </HelmetProvider>

      <div className="ak-content">
        <Link to="/Epaper">
          <div style={{ color: "white", marginTop: "20px" }}>
            <img
              className="site-logo"
              width={120}
              height={50}
              loading="lazy"
              src="/design/images/logo-mike1.png"
              alt="शब्द टुडे Logo"
              style={{ paddingLeft: "10px" }}
            />
            <hr />
          </div>
        </Link>

        <div className="container" style={{ backgroundColor: "black" }}>
          <div className="row">
            <div className="ak_column_2 col-md-12 content-column">
              {/* Image of E-paper */}
              {newsDetails.pdfUrlNews && (
                <div style={{ position: "relative", display: "flex", justifyContent: "center" }}>
                  <img
                    alt="E-paper"
                    src={`${baseUrl}${newsDetails.pdfUrlNews}`}
                    height="500px"
                    className="m-auto"
                    style={{ transform: "scale(1)" }}
                  />
                  
                  {/* Download button */}
                  {/* <button
                    onClick={handleDownload}
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "-80px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      zIndex: 10,
                    }}
                    className="download-icon"
                  >
                    <i className="fa fa-download" style={{ color: "white" }} aria-hidden="true"></i>
                  </button> */}
                </div>
              )}
            </div>
          </div>
        </div>

        <div style={{ color: "white", marginTop: "0%" }}>
          <hr />
          <p style={{ textAlign: "center" }}>
            हमारे साथ विज्ञापन करें | www.shabadtoday.com | info@shabadtoday.org
          </p>
          <p style={{ textAlign: "center" }}>
            कॉपीराइट © 2024 शब्द टुडे.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EpaperDetails;
