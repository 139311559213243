import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { hi } from "date-fns/locale";
import { Circles } from "react-loader-spinner";

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength);
    //return text.substring(0, maxLength) + '...';
  }
  return text;
};

const stripHtmlTags = (html, maxLength) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const strippedText = tempDiv.textContent || tempDiv.innerText || "";
  return truncateText(strippedText, maxLength);
};
function AnyaKhel() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.shabdtoday.com/api/home/khelKhabars"
        );
        setPosts(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  // Define your base URL for news images

  const formatDateInHindi = (dateString) => {
    const date = new Date(dateString);
    const monthsInHindi = [
      "जनवरी",
      "फरवरी",
      "मार्च",
      "अप्रैल",
      "मई",
      "जून",
      "जुलाई",
      "अगस्त",
      "सितंबर",
      "अक्टूबर",
      "नवंबर",
      "दिसंबर",
    ];
    const day = date.getDate();
    const month = monthsInHindi[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  const generateShareLinks = (mainKhabarId, newsHeading) => {
    const encodedNewsHeading = encodeURIComponent(newsHeading);
    const url = `${window.location.origin}/KhelDetailsD/${mainKhabarId}`;
    return {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${encodedNewsHeading}`,
      whatsapp: `https://api.whatsapp.com/send?text=${encodedNewsHeading} ${url}`
    };
  };
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Circles
          height="30"
          width="30"
          color="#f7265c"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const baseUrl = "https://api.shabdtoday.com/";

  const image_resize = "https://api.shabdtoday.com/api/home/resize";
  return (
    <div>
      <div
        data-vc-full-width="true"
        data-vc-full-width-init="false"
        className="container vc_row dark vc_custom_1664728292054 vc_row-has-fill"
      >
        <div className="ak_vc_container">
          <div className="wpb_column ak_column_3 vc_column_container vc_col-sm-12">
            <div className="ak_vc_wrapper wpb_wrapper">
              <div
                className="ak-block ak-block-custom-header  ak-block-width-3 clearfix"
                id="block_65f7f61f36732_16"
              >
                <div className="ak-block-inner clearfix" />
              </div>
              <div
                className="main-wdgt glry-bg"
                data-vars-widget-type="category"
                data-vars-widget-name="image gallery"
                data-vars-orderid={11}
                id="widget-rs-4996"
              >
                <div className="wdgt-hdng">
                  <h1>
                    <Link to="/Khels" title="अन्य खेल" aria-label="अन्य खेल">
                      अन्य खेल
                    </Link>
                  </h1>
                </div>
                <div className="wdgt-wrap" id="r_main_gallery_24">
                  <div className="wdgt-lft">
                    {posts.khelKhabars.slice(0, 1).map((newsItem) => (
                     <div key={newsItem.mainKhabarId}
                        className="card-lg"
                        data-nid={10118981}
                        to={`/KhelDetailsD/${newsItem.mainKhabarId}`}
                        title="जब उर्फी जावेद को कर दिया था शो से बाहर, तबीयत खराब होने पर भी सेट पर बुलाया"
                      aria-label={stripHtmlTags(newsItem.newsHeading, 30)}
                      >
                        <i className="img-sizer" />
                        <img
                          className="card-img lazy-img"
                          loading="lazy"
                          src={
                            image_resize +
                            "?url=" +
                            baseUrl +
                            newsItem.newsImage +
                            "&w=471&h=265&outtype=webp"
                          }
                          width="466px"
                          height="262px"
                          srcSet={
                            image_resize +
                            "?url=" +
                            baseUrl +
                            newsItem.newsImage +
                            "&w=471&h=265&outtype=webp"
                          }
                          alt="जब उर्फी जावेद को कर दिया था शो से बाहर, तबीयत खराब होने पर भी सेट पर बुलाया"
                          title="जब उर्फी जावेद को कर दिया था शो से बाहर, तबीयत खराब होने पर भी सेट पर बुलाया"
                        />
                        <h1 className="big-news-title">
                        <Link to={`/KhelDetailsD/${newsItem.mainKhabarId}`}> {stripHtmlTags(newsItem.newsHeading, 60)}</Link> 
                        </h1>
                        <small className="pht-contnr">
                          <i className="icon-photo" />
                        </small>
                      </div>
                    ))}
                     {posts.khelKhabars.slice(1, 2).map((newsItem) => {
                      const shareLinks = generateShareLinks(newsItem.mainKhabarId, newsItem.newsHeading);
                      return (
                     <div key={newsItem.mainKhabarId}>
                     <div
                        to={`/KhelDetailsD/${newsItem.mainKhabarId}`}
                        data-nid={10117664}
                        title="36 साल बाद कुछ ऐसी दिखती हैं पुराने 'महाभारत' की द्रौपदी, 3 बार की थी आत्महत्या की कोशिश"
                        className="card-sm lft-card lft-card-m"
                        aria-label={stripHtmlTags(newsItem.newsHeading, 30)}
                      >
                        <div className="sm-lft">
                          <small
                            className="pht-contnr"
                            style={{ zIndex: 33, right: 5 }}
                          >
                            <i className="icon-photo" />
                          </small>
                          <i className="img-sizer" />
                          <img
                            className="card-img lazy-img"
                            loading="lazy"
                            src={
                              image_resize +
                              "?url=" +
                              baseUrl +
                              newsItem.newsImage +
                              "&w=125&h=70&outtype=webp"
                            }
                            width="125px"
                            height="70px"
                            srcSet={
                              image_resize +
                              "?url=" +
                              baseUrl +
                              newsItem.newsImage +
                              "&w=125&h=70&outtype=webp"
                            }
                            alt="36 साल बाद कुछ ऐसी दिखती हैं पुराने 'महाभारत' की द्रौपदी, 3 बार की थी आत्महत्या की कोशिश"
                            title="36 साल बाद कुछ ऐसी दिखती हैं पुराने 'महाभारत' की द्रौपदी, 3 बार की थी आत्महत्या की कोशिश"
                          />
                        </div>
                        <div className="sm-rght">
                          <h1 className="wdgt-subtitle1">
                            <Link to={`/KhelDetailsD/${newsItem.mainKhabarId}`}>{stripHtmlTags(newsItem.newsHeading, 60)}</Link>  
                            <span className="socialmedia"> &nbsp;&nbsp;
                                  <Link to ={shareLinks.facebook} target="_blank" rel="noopener noreferrer" aria-label="facebook" ><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                                  &nbsp;&nbsp;  
                                  <Link to ={shareLinks.whatsapp} target="_blank" rel="noopener noreferrer" aria-label="whatsapp"><i className="fa fa-whatsapp" aria-hidden="true"></i></Link> </span> 
                          </h1>              
                        </div>
                      </div>
                     </div>
                    );
                  })}
                  </div>
                  <div className="wdgt-rgt">
                    {posts.khelKhabars.slice(2, 6).map((newsItem) => {
                      const shareLinks = generateShareLinks(newsItem.mainKhabarId, newsItem.newsHeading);
                      return (
                     <div key={newsItem.mainKhabarId}>
                     <div
                        className="card-sm"
                        to={`/KhelDetailsD/${newsItem.mainKhabarId}`}
                        data-nid={10113539}
                        title="जून में सिनेमाघरों में रिलीज होने जा रही हैं ये 6 फिल्में, इस लिस्ट में एक हॉरर फिल्म का भी नाम शामिल"
                      aria-label= {stripHtmlTags(newsItem.newsHeading, 30)}
                      >
                        <div className="sm-lft">
                          <small
                            className="pht-contnr"
                            style={{ zIndex: 33, right: 5 }}
                          >
                            <i className="icon-photo" />
                          </small>
                          <i className="img-sizer" />
                          <img
                            className="card-img lazy-img"
                            loading="lazy"
                            src={
                              image_resize +
                              "?url=" +
                              baseUrl +
                              newsItem.newsImage +
                              "&w=125&h=70&outtype=webp"
                            }
                            width="125px"
                            height="70px"
                            srcSet={
                              image_resize +
                              "?url=" +
                              baseUrl +
                              newsItem.newsImage +
                              "&w=125&h=70&outtype=webp"
                            }
                            alt="जून में सिनेमाघरों में रिलीज होने जा रही हैं ये 6 फिल्में, इस लिस्ट में एक हॉरर फिल्म का भी नाम शामिल"
                            title="जून में सिनेमाघरों में रिलीज होने जा रही हैं ये 6 फिल्में, इस लिस्ट में एक हॉरर फिल्म का भी नाम शामिल"
                          />
                        </div>
                        <div className="sm-rght">
                          <h1 className="wdgt-subtitle1">
                          <Link to={`/KhelDetailsD/${newsItem.mainKhabarId}`}>{stripHtmlTags(newsItem.newsHeading, 60)}</Link>  
                            <span className="socialmedia">    &nbsp;&nbsp;&nbsp;
                                  <Link to ={shareLinks.facebook} target="_blank" rel="noopener noreferrer" aria-label="facebook"><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                                  &nbsp;&nbsp;  &nbsp;&nbsp;
                                  <Link to ={shareLinks.whatsapp} target="_blank" rel="noopener noreferrer" aria-label="whatsapp"><i className="fa fa-whatsapp" aria-hidden="true"></i></Link> </span> 
                          </h1>                   
                        </div>
                      </div>
                     </div>
                    );
                  })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnyaKhel;
